let FPConfing = window.FPConfing || {}
const appConfig = {
  // apiPrefix: "http://dev.auth.captravelassistance.com:9003/api",
  apiPrefix: "https://dev.api.captravelassistance.com",
  authAPIPrefix: "/api",
  authAPIHost: "/AuthV2/login",
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  enableMock: false,

  // Existing Ones
  API1: FPConfing && FPConfing.API1 ? FPConfing.API1 : "https://dev.api.captravelassistance.com",
  AUTH_API:
    FPConfing && FPConfing.API2 ? FPConfing.API2 : "http://dev.auth.captravelassistance.com:9003/",
  API3: FPConfing && FPConfing.API3 ? FPConfing.API3 : "https://dev.api.captravelassistance.com",
  DASHBOARD_URL:
    FPConfing && FPConfing.DASHBOARD_URL
      ? FPConfing.DASHBOARD_URL
      : "https://dev.api.captravelassistance.com:3000",
  allCountriesCountryId:
    FPConfing && FPConfing.allCountriesCountryId ? FPConfing.allCountriesCountryId : 277,
  allCountriesCountryCode:
    FPConfing && FPConfing.allCountriesCountryCode ? FPConfing.allCountriesCountryCode : "All",
  stripeKey:
    FPConfing && FPConfing.stripeKey
      ? FPConfing.stripeKey
      : "pk_test_xE1A4jL9sDBnyBWljkVfKD0S00CPA7kVzU",
  b2cBaseUrl: "/cap",
  b2b2cBaseUrl: "/partner",
  wpEditQuoteUrl: "https://captravelassistance.com/",
  agentCode: 1640, //265 for prod, 1640 -dev
  wpProductToOrder: 1,
  b2b2CProductToOrder: 4, //7 for prod 4-dev
  server: "dev", // "prod" for production  "dev" -dev
  ttl: 300000,
  clientTokenPaypal:
    "Abmle8eLyxI7-pWsG5dlUNkw7CmuIZsmz1qKcTm6RoPLo0JHAyyACv_c3nuSf7hVGkp6Al22Ky7hugRM",
  //dev  -   Abmle8eLyxI7-pWsG5dlUNkw7CmuIZsmz1qKcTm6RoPLo0JHAyyACv_c3nuSf7hVGkp6Al22Ky7hugRM
  //prod -   AdUorQYFW95ItBjosIlhKfU0bKtwPmWUIBCWL3Oose0Pvd357xwyttF_g4DDPzU6TJQPP-meMnZoSN20
}

export default appConfig
