import React, { memo, useMemo, lazy, Suspense } from "react"
import { Loading } from "components/shared"
import { useSelector } from "react-redux"
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK,
} from "constants/theme.constant"
import useAuth from "utils/hooks/useAuth"
import useDirection from "utils/hooks/useDirection"
import useLocale from "utils/hooks/useLocale"
import { matchRoutes, useLocation } from "react-router-dom"

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import("./ClassicLayout")),
  [LAYOUT_TYPE_MODERN]: lazy(() => import("./ModernLayout")),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import("./StackedSideLayout")),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import("./SimpleLayout")),
  [LAYOUT_TYPE_DECKED]: lazy(() => import("./DeckedLayout")),
  [LAYOUT_TYPE_BLANK]: lazy(() => import("./BlankLayout")),
}

const Layout = () => {
  const location = useLocation()
  console.log(location)
  const buyRoute = matchRoutes([{ path: "/buy" }], location)
  const layoutType = useSelector((state) => state.theme.layout.type)

  const { authenticated } = useAuth()

  useDirection()

  useLocale()

  const AppLayout = useMemo(() => {
    if (authenticated) {
      return layouts[layoutType]
    }
    if (buyRoute) {
      return lazy(() => import("./BuyLayout"))
    }
    return lazy(() => import("./AuthLayout"))
  }, [layoutType, buyRoute, authenticated])

  return (
    <Suspense
      fallback={
        <div className="flex flex-auto flex-col h-[100vh]">
          <Loading loading={true} />
        </div>
      }
    >
      <AppLayout />
    </Suspense>
  )
}

export default memo(Layout)
